<template>
  <div class="container">
    <div class="order-description">
      <p>Заказ <span style="font-weight: bold;">№{{ order.id }} от {{ order.date }}</span></p>
      <p>Сумма: <span style="font-weight: bold;">{{ orderPrice }}</span></p>
      <template v-if="!isRedacted">
<!--         <p>ФИО: <span style="font-weight: bold;">{{ order.surname }} {{ order.name }} {{ order.patronymic }}</span></p> -->
        <p>Контактная информация: <span style="font-weight: bold;">{{ order.email }}</span></p>
        <p>Предпочтительный способ связи: <span style="font-weight: bold;">{{ order.messenger }}</span></p>
<!--         <p>Адрес доставки: <span style="font-weight: bold;">{{ order.adress }}</span></p> -->
        <p v-if="order.track_number !=''">Трек-номер: <span style="font-weight: bold;">{{ order.track_number }}</span></p>
        <p>Статус заказа: <span style="font-weight: bold;">{{ orderStatus }}</span></p>
      </template>
      <template v-else>
        <div class="order-redact">
<!--           <label for="">
            <span>Имя</span>
            <input type="text" required v-model="name">
          </label>
          <label for="">
            <span>Фамилия</span>
            <input type="text" required v-model="surname">
          </label>

          <label for="">
            <span>Отчество</span>
            <input type="text" required v-model="patronymic">
          </label>

          <label for="">
            <span>Номер телефона</span>
            <input type="tel" required v-model="phone_number">
          </label> -->

          <label for="">
            <span>Контактная информация:</span>
            <input type="email" required v-model="email">
          </label>
          
          <label for="">
            <span>Предпочтительный способ связи</span>
            <select v-model="messenger">
              <option disabled value="">Выберите один из вариантов</option>
              <option>Email</option>
              <option>Telegram</option>
              <option>ВК</option>
              <option>WhatsApp</option>
            </select>
          </label>

<!--           <label for="">
            <span>Адрес доставки</span>
            <textarea required v-model="adress"></textarea>
          </label> -->

          <label for="">
            <span>Трек-номер</span>
            <input type="text" required v-model="track_number">
          </label>

          <label for="">
            <span>Статус</span>
            <select required v-model="status">
              <option value="0">ожидает подтверждения</option>
              <option value="1">ожидает оплаты</option>
              <option value="2">ожидает отправки</option>
              <option value="3">отправлен</option>
              <option value="4">на пункте выдачи</option>
              <option value="5">получен</option>
              <option value="6">отменен</option>
            </select>
          </label>
        </div>
      </template>

    </div>
    <div class="order-btns" v-if="order.status < 5">
      <button v-if="!isRedacted" class="btn-standart" @click="isRedacted = true">Изменить информацию</button>
      <template v-else>
        <button class="btn-save" @click="saveInfo">Сохранить</button>
        <button class="btn-cancel" @click="isRedacted = false">Отменить</button>
      </template>
    </div>
    <div class="products-list">
      <ul>
        <li v-for="item in orderProducts" :key="item">
          <ProductCard :productInfo="item" :viewMode="'order'" />
          <div class="product-btns">
            <button class="btn-cancel" @click="openDeleteModal(item)">Удалить</button>
            <button class="btn-standart" @click="openEditModal(item)">Заменить</button>
          </div>
        </li>
      </ul>
    </div>

    <transition name="modal-fade">
      <AcceptModal v-if="modalState" @onCloseModal="closeModal" @onAccept="deleteProduct" :item="modalItem"
        :message="'Уверены, что хотите удалить этот товар из заказа?'" :contentLocation="'products'"></AcceptModal>
    </transition>
    <transition name="modal-fade">
      <productsListModal v-if="modalStateEdit" @onCloseModal="modalStateEdit = false" @onChangeItem="changeItemProducts"
        :mode="'edit'"></productsListModal>
    </transition>

  </div>
</template>

<script>
import ProductCard from '@/components/products/ProductCard.vue'
import AcceptModal from '@/components/modals/AcceptModal.vue'
import productsListModal from '@/components/modals/productsListModal.vue'
import ProductDataService from "@/service/ProductDataService";

export default {
  name: 'orderItem',
  data() {
    return {
      order: {},
      orderProducts: [],
      isHidden: false,
      orderPrice: 0,
      isRedacted: false,
      modalState: false,
      modalStateEdit: false,
      modalItem: {},
      modalItemID: 0,
      modalEditItem: {},
/*       name: this.orderInfo.name,
      surname: this.orderInfo.surname,
      patronymic: this.orderInfo.patronymic, */
      email: this.orderInfo.email,
      messenger: this.orderInfo.messenger,
/*       adress: this.orderInfo.adress, */
      status: this.orderInfo.status,
      track_number: this.orderInfo.track_number,

    }
  },
  components: {
    ProductCard,
    AcceptModal,
    productsListModal
  },
  props: ['orderInfo'],
  computed: {
    orderStatus() {
      switch (Number(this.status)) {
        case 0:
          return 'ожидает подтверждения';
        case 1:
          return 'подтвержден, ожидает оплаты';
        case 2:
          return 'оплачен, ожидает отправки';
        case 3:
          return 'отправлен';
        case 4:
          return 'ожидает на пункте выдачи';
        case 5:
          return 'получен';
        case 6:
          return 'отменен';
        default:
          return 'ожидает подтверждения'
      }
    }
  },
  methods: {
    openDeleteModal(item) {
      this.modalItem = item.images[0];
      this.modalItemID = item.id;
      this.modalState = true;
    },
    closeModal() {
      this.modalState = false;
    },
    openEditModal(item) {
      this.modalEditItem = item;
      this.modalStateEdit = true;
    },
    changeItemProducts(item) {
      let productsArr = this.order.products.split(',');
      let indexList = productsArr.findIndex(el => Number(el) == Number(this.modalEditItem.id));
      let indexObjects = this.orderProducts.findIndex(el => Number(el.id) == Number(this.modalEditItem.id));
      productsArr[indexList] = item.id;
      this.orderProducts[indexObjects] = item;
      this.order.products = productsArr.join(',');
      this.$store.dispatch('order/updateOrderProducts', this.order);
    },
    deleteProduct() {
      let productsArr = this.order.products.split(',');
      let indexList = productsArr.findIndex(el => Number(el) == Number(this.modalItemID));
      let indexObjects = this.orderProducts.findIndex(el => Number(el.id) == Number(this.modalItemID));
      this.orderProducts.splice(indexObjects, 1);
      productsArr.splice(indexList, 1);
      this.order.products = productsArr.join(',');
      this.$store.dispatch('order/updateOrderProducts', this.order);
    },
    saveInfo() {
      this.isRedacted = true;
/*       this.order.name = this.name;
      this.order.surname = this.surname;
      this.order.patronymic = this.patronymic; */
      this.order.email = this.email;
      this.order.messenger = this.messenger;
/*       this.order.adress = this.adress; */
      this.order.status = this.status;
      this.order.track_number = this.track_number;
      this.$store.dispatch('order/updateOrder', this.order);
      this.isRedacted = false;
    }
  },
  created() {

    this.order = JSON.parse(JSON.stringify(this.orderInfo));
    let productsList = this.order.products.split(',');
    productsList.forEach(product => {
      ProductDataService.getItem(product).then((res) => {

        if (res.data?.id) {
          let product = new Object();
          let itemImages = [];

          res.data.images.split(" $;$ ").forEach(image => {

            let imageItem = new Object();
            imageItem.type = image.slice(1, 4);
            imageItem.src = image.slice(5, image.length);

            if (imageItem.src != "") {
              itemImages.push(imageItem)
            }

          });
          product = res.data;
          product.images = itemImages;
          product.name = res.data.name.replace(/&quot;/g, '"');
          this.orderProducts.push(product);
          if (product.discountPrice == 0) this.orderPrice += Number(product.price);
          else this.orderPrice += Number(product.discountPrice);
        }
      });
    })

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {

  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
}

.order-description {
  padding-left: 10px;
  margin: 0;
}

.order-description p {
  margin: 0;
  padding-bottom: 10px;
}

.products-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-right: 20px;
}

.products-list ul {
  padding-bottom: 20px;
  padding-top: 10px;
  padding-left: 0;
  max-width: 100%;
  display: grid;
  height: 400px;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-flow: column;
  grid-auto-columns: 150px;
  grid-auto-rows: 250px;
  overflow: scroll;
  gap: 20px 10px;
  margin: 0px;
}

.order-btns {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.order-btns button {
  width: 300px;
}

.order-redact {
  display: flex;
  flex-direction: column;
}

.order-redact label {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.order-redact input,
.order-redact textarea,
.order-redact select {
  width: 100%;
  padding: 5px;
  margin: 5px;
  margin-left: 0;
  border-radius: 5px;
  border: 1px solid gray;
}

.order-redact select {
  background-color: rgb(253, 240, 255);
}

.product-btns {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.product-card {
  border-radius: 10px;
}
</style>
